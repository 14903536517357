<template>
    <div class="elmain">

        <el-button type="success" @click="add">新增工序模板</el-button>

        <el-input style="width: 500px;    margin-left: 80px;" placeholder="请输入工序名称" v-model="name"
                  autocomplete="off"></el-input>
        <el-button type="primary" @click="cx">查询</el-button>
        <el-table :data="list" border style="width: 100%;margin-top: 20px;">
            <el-table-column prop="process_name" label="工序名称"></el-table-column>
<!--            <el-table-column prop="rate" label="权重占比（%）"></el-table-column>-->
<!--            <el-table-column prop="company_group_id" label="所属部门" :formatter="swgroup"></el-table-column>-->
            <el-table-column prop="created_at" sortable label="创建时间"></el-table-column>
            <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="warning" size="small">编辑</el-button>
                    <el-button type="danger" @click="deleteg(scope.row)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="block" style="    margin-top: 10px;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit"
                           layout="sizes, prev, pager, next" :total="total">
            </el-pagination>

        </div>

        <!-- 修改框 -->
        <el-dialog title="订单信息" :visible.sync="dialogFormVisible">
            <el-form :model="dialogData">
                <el-form-item label="工序名称" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.process_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权重占比" :label-width="formLabelWidth">
                    <el-input type="number" min="1" max="100" v-model="dialogData.rate" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="顺序" :label-width="formLabelWidth">
                    <el-input type="number" v-model="dialogData.sort" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所属部门" :label-width="formLabelWidth">
                    <el-select filterable v-model="dialogData.company_group_id" placeholder="请选择" style="width: 100%;">
                        <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog>

        </el-dialog>
        <Form ref="form" :visible.sync="dialogFormVisible"/>
    </div>
</template>

<script>
import {getProcessTemplate, addProcessTemplate, putProcessTemplate, deleteProcessTemplate, group} from "@/service/api";
import Form from "@/views/processTemplate/components/form";

export default {
    components: {Form},
    data() {
        return {
            tableData: [],
            page: 1,
            name: '',
            limit: 10,
            list: [],
            total: 0,
            customerlist: [],
            dialogFormVisible: false,
            formLabelWidth: '120px',
            dialogData: {
                process_name: '',
                rate: '',
                company_group_id: '',
                sort: ''
            },
            type: '',
            grouplist: []
        };
    },
    methods: {
        swgroup(row) {
            let text = '';
            this.grouplist.forEach(item => {
                if (item.id === row.company_group_id) {
                    text = item.name;
                }
            });
            return text;
        },
        getgroup() {
            group({limit: 100, page: 1}).then(res => {
                this.grouplist = res.data.list;
            });
        },
        cx() {
            this.page = 1;
            this.getlist();
        },
        handleClick(row) {
            // console.log(row);
            // this.dialogFormVisible = true;
            // this.type = "put";
            // this.dialogData = {
            //     process_name: row.process_name,
            //     rate: row.rate,
            //     company_group_id: row.company_group_id,
            //     id: row.id,
            //     sort: row.sort
            // };
            const params = {
                groupList: this.grouplist,
                formVisible: true,
                type: "edit",
                row: row
            };
            this.$refs.form.init(params);
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.limit = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getlist();
        },
        getlist() {
            this.$loading({
                fullscreen: true
            });
            let data = {limit: this.limit, page: this.page, name: this.name};
            getProcessTemplate(data).then(res => {
                res.data.list.forEach(item => {
                    item.order_date = item.order_date ? this.dayjs(item.order_date).format('YYYY-MM-DD') : '无';
                    item.delivery_date = item.delivery_date ? this.dayjs(item.delivery_date).format('YYYY-MM-DD') : '无';
                });
                this.list = res.data.list;
                this.total = res.data.total;
            }).finally(() => {
                this.$loading().close();
            });
        },
        add() {
            const params = {
                groupList: this.grouplist,
                formVisible: true,
                type: "add"
            };
            this.$refs.form.init(params);
        },
        deleteg(i) {
            console.log(i);
            let that = this;
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id
                };
                deleteProcessTemplate(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        submit() {
            console.log(this.dialogData);
            if (this.dialogData.process_name === '') {
                this.$message.error('请输入工序名称');
                return;
            }
            if (this.dialogData.rate === '') {
                this.$message.error('请输入所属部门');
                return;
            }
            if (this.dialogData.rate === '') {
                this.$message.error('请输入权重占比');
                return;
            }
            if (this.dialogData.rate > 100 || this.dialogData.rate <= 0) {
                this.$message.error('权重占比错误');
                return;
            }
            this.dialogData.rate = Number(this.dialogData.rate);
            if (this.type === 'add') {
                addProcessTemplate(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }

                });
            }
            if (this.type === 'put') {
                putProcessTemplate(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }
                });
            }

        }
    },
    mounted() {
        this.getgroup();
        this.getlist();
    }
};
</script>
<style scoped>
.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .main-conent {
        margin: 10px;
    }

    .elmain[data-v-7ad48fd0] {
        padding: 10px;
    }

    .el-dialog {
        width: 80% !important;
    }
}
</style>
