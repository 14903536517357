<template>
    <el-dialog title="工序模板"
               :visible.sync="formVisible"
               width="70%">
        <div>
            <el-form ref="formData" :model="form" :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工序模板名称" label-width="150px" prop="process_name">
                            <el-input v-model="form.process_name" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="form.items" border stripe>
                        <el-table-column label="工序名称">
                            <template slot-scope="{ row, $index }">
                                <el-form-item
                                    :prop="`items.${$index}.process_name`"
                                    :rules="rules.process_name"
                                >
                                    <el-input v-model="row.process_name"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属部门">
                            <template slot-scope="{ row, $index }">

                                <el-form-item
                                    :prop="`items.${$index}.company_group_id`"
                                    :rules="rules.company_group_id"
                                >
                                    <el-select filterable v-model="row.company_group_id" placeholder="请选择"
                                               style="width: 100%;">
                                        <el-option v-for="item in groupList" :key="item.id" :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="权重占比（%）">
                            <template slot-scope="{ row, $index }">
                                <el-form-item
                                    :prop="`items.${$index}.rate`"
                                    :rules="rules.rate"
                                >
                                    <el-input-number v-model="row.rate"></el-input-number>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button v-if="scope.$index > 0" type="danger" size="mini" @click="del(scope.$index)"
                                           icon='el-icon-delete'></el-button>
                                <el-button type="primary" size="mini" @click="add" icon='el-icon-plus'></el-button>
                                <el-button size="mini" :disabled="scope.$index===0"
                                           @click="moveUp(scope.$index,scope.row)">
                                    <i class="el-icon-arrow-up"></i>
                                </el-button>
                                <el-button size="mini" :disabled="scope.$index===(form.items.length-1)"
                                           @click="moveDown(scope.$index,scope.row)">
                                    <i class="el-icon-arrow-down"></i>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" @click="onSubmit">立即创建</el-button>
                        <el-button @click="handleCancel">取消</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import {addProcessTemplate, putProcessTemplate} from "@/service/api";
import NP from "number-precision";

export default {
    name: "Form",
    data() {
        return {
            form: {
                process_name: '',
                items: [
                    {process_name: "", company_group_id: "", rate: ""}
                ]
            },
            rules: {
                process_name: [
                    {required: true, trigger: ["blur", "change"], message: "请输入"}
                ],
                company_group_id: [
                    {required: true, trigger: ["blur", "change"], message: "请输入"}
                ]
            },
            formVisible: false,
            type: 'add',
            groupList: []
        };
    },
    methods: {
        init(params) {
            this.formVisible = params.formVisible;
            this.groupList = params.groupList;
            if (params.row) {
                this.$nextTick(() => {
                    this.form = JSON.parse(JSON.stringify(params.row));
                    if (!this.form.items || this.form.items.length === 0) {
                        this.form.items[0] = {
                            process_name: "",
                            company_group_id: "",
                            rate: ""
                        };
                    }
                });
            } else {
                this.$refs.formData.resetFields();
                this.form.items = [
                    {
                        process_name: "",
                        company_group_id: "",
                        rate: ""
                    }
                ];
            }
            this.type = params.type;
        },
        onSubmit() {
            if (this.$refs['formData'].validate((valid) => {
                if (valid) {
                    if (this.type === 'add') {
                        addProcessTemplate(this.form).then(() => {
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                            this.$parent.getlist();
                            this.formVisible = false;
                        });
                    }
                    if (this.type === 'edit') {
                        putProcessTemplate(this.form).then(() => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.$parent.getlist();
                            this.formVisible = false;
                        });
                    }
                } else {
                    return false;
                }
            })) ;
        },
        add() {
            this.form.items.push({
                process_name: "",
                rate: ''
            });
            this.countRate();
        },
        del(index) {
            this.form.items.splice(index, 1);
            this.countRate();
        },
        moveUp(index) {
            let that = this;
            if (index > 0) {
                let upDate = that.form.items[index - 1];
                upDate.execSequence = index;
                that.form.items.splice(index - 1, 1);
                that.form.items[index - 1].execSequence = index - 1;
                that.form.items.splice(index, 0, upDate);
            } else {
                this.$message.warning("已经是第一条，不可上移");
            }
        },
        moveDown(index) {
            let that = this;
            if (index + 1 === that.form.items.length) {
                this.$message.warning("已经是最后一条，不可下移");
            } else {
                let downDate = that.form.items[index + 1];
                downDate.execSequence = index;
                that.form.items.splice(index + 1, 1);
                that.form.items.splice(index, 0, downDate);
                that.form.items[index + 1].execSequence = index + 1;
            }
        },
        handleCancel() {
            this.$refs.formData.resetFields();
            this.$nextTick(() => {
                this.formVisible = false;
            });
        },
        // 每道工序平分权重
        countRate(){
            const itemCount = this.form.items.length;
            const rate = NP.round(NP.divide(100 / itemCount), 0);
            let allRate = 0;
            for(let i = 0; i < itemCount; i++) {
                const item = this.form.items[i];
                if(i === itemCount - 1) {
                    item.rate = NP.round(NP.minus(100 - allRate), 0);
                } else {
                    item.rate = rate;
                    allRate = NP.plus(allRate + rate);
                }
            }
        }
    }
};

export class form {
}
</script>

<style scoped>

</style>
